/*
 * @Descripttion: 教学模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/04/25
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/teacher/teateaching/";
const PREFIX2 = "/teacher/teateachingcourseware/"; // 课件
const PREFIX2_2 = "/teacher/tealessonscourseware/"; // 课件(授课)
const PREFIX3 = "/teacher/teateachingknowledge/"; // 知识点
const PREFIX3_2 = "/teacher/tealessonsknowledge/"; // 知识点(授课)
const PREFIX4 = "/teacher/teateachingpractice/"; // 课堂练习
const PREFIX4_2 = "/teacher/tealessonspractice/"; // 课堂练习(授课)
const PREFIX5 = "/teacher/teateachinghomework/"; // 作业
const PREFIX5_2 = "/teacher/tealessonshomework/"; // 作业(授课)
const PREFIX6 = "/teacher/teaclassschedule/"; // 授课
const PREFIX7 = "/teacher/teacourseware/"; // 课件资源
const teafavoritelink = "/teacher/teafavoritelink/"; // 课件资源

/*******
 * @Descripttion: 获取备课数据关联id
 * @Author: Dyf
 * @param {number} sccou_cha_id 备课关联章节id
 * @return {*}
 */
export async function $getPrepareId(sccou_cha_id) {
  try {
    return await HTTP.post(`${PREFIX}get_info`, { sccou_cha_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取每周教师备课课程表
 * @Author: Dyf
 * @param {number} time 每周其中一天时间戳
 * @return {*}
 */
export async function $getTeacherPrepare(time) {
  try {
    return await HTTP.post(`${PREFIX}get_teaclass`, { time });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 设置占课内容
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $setTeachTime(params) {
  try {
    return await HTTP.post(`${PREFIX}set_class_time`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 清除占课内容
 * @Author: Dyf
 * @param {number} tecla_id 课程id
 * @return {*}
 */
export async function $clearTeachTime(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX}clean_content`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学校备课信息列表
 * @Author: Dyf
 * @param {object} params.sccou_cha_id
 * @return {*}
 */
export async function $getSchoolPrepares(params) {
  try {
    return await HTTP.get(`${PREFIX}get_school_share_class`, { ...params });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取平台备课信息列表
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $getPlatformPrepares(params) {
  try {
    return await HTTP.get(`${PREFIX}get_platform_share_class`, { ...params });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 引用备课数据(备课)
 * @Author: Dyf
 * @param {number} copy_tetea_id 备课id（引用数据）
 * @param {number} tetea_id 备课id
 * @return {*}
 */
export async function $quotePrepare2(copy_tetea_id, tetea_id) {
  try {
    return await HTTP.post(`${PREFIX}quote`, {
      copy_tetea_id, tetea_id
    });
  } catch (error) {
    return error;
  }
}

/* 课件 */

/*******
 * @Descripttion: 获取备课课件
 * @Author: Dyf
 * @param {number} tetea_id 备课关联id
 * @return {*}
 */
export async function $getPrepareCourseware(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX2}list_info`, { tetea_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除备课课件
 * @Author: Dyf
 * @param {number} id 课件id
 * @return {*}
 */
export async function $delPrepareCourseware(id) {
  try {
    return await HTTP.post(`${PREFIX2}delete`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传备课课件
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $uploadPrepareCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}

/* 授课 - 课件 */
/*******
 * @Descripttion: 获取备课课件
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getTeachCourseware(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX2_2}list_info`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除备课课件
 * @Author: Dyf
 * @param {number} id 课件id
 * @return {*}
 */
export async function $delTeachCourseware(id) {
  try {
    return await HTTP.post(`${PREFIX2_2}delete`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传备课课件
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $uploadTeachCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX2_2}add`, params);
  } catch (error) {
    return error;
  }
}

/* 知识点 */

/*******
 * @Descripttion: 知识点列表
 * @Author: Dyf
 * @param {number} tetea_id 备课关联id
 * @return {*}
 */
export async function $getPrepareKnowledge(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX3}list_info`, { tetea_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增知识点
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addPrepareKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑知识点
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editPrepareKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除知识点
 * @Author: Dyf
 * @param {number} id 知识点id
 * @return {*}
 */
export async function $delPrepareKnowledge(id) {
  try {
    return await HTTP.post(`${PREFIX3}delete`, { id });
  } catch (error) {
    return error;
  }
}

/* 授课 - 知识点 */

/*******
 * @Descripttion: 知识点列表
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getTeachKnowledge(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX3_2}list_info`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增知识点
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addTeachKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3_2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑知识点
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editTeachKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3_2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除知识点
 * @Author: Dyf
 * @param {number} id 知识点id
 * @return {*}
 */
export async function $delTeachKnowledge(id) {
  try {
    return await HTTP.post(`${PREFIX3_2}delete`, { id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 下载知识点批量导入模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadTemplate() {
  try {
    return await HTTP.post(`${PREFIX3}importtemplate`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 备课批量导入知识点
 * @Author: Dyf
 * @return {*}
 */
export async function $importKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3}import`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 授课批量导入知识点
 * @Author: Dyf
 * @return {*}
 */
export async function $importLessonKnowledge(params) {
  try {
    return await HTTP.post(`${PREFIX3_2}import`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 备课批量导入课堂练习
 * @Author: Dyf
 * @return {*}
 */
export async function $importTeachingPractise(params) {
  try {
    return await HTTP.post(`${PREFIX4}import`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 授课批量导入课堂练习
 * @Author: Dyf
 * @return {*}
 */
export async function $importLessonPractise(params) {
  try {
    return await HTTP.post(`${PREFIX4_2}import`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 下载练习题批量导入模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadPractiseTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX4}importtemplate`,params);
  } catch (error) {
    return error;
  }
}

/* 课堂练习 */

/*******
 * @Descripttion: 课堂练习列表
 * @Author: Dyf
 * @param {number} tetea_id 备课关联id
 * @return {*}
 */
export async function $getPreparePractise(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX4}list_info`, { tetea_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课堂练习详情
 * @Author: Dyf
 * @param {number} tetea_pra_id 课堂练习id
 * @return {*}
 */
export async function $preparePractiseDetail(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4}info`, { tetea_pra_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增课堂练习
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addPreparePractise(params) {
  try {
    return await HTTP.post(`${PREFIX4}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑课堂练习
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editPreparePractise(params) {
  try {
    return await HTTP.post(`${PREFIX4}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除课堂练习
 * @Author: Dyf
 * @param {number} tetea_pra_id 课堂练习id
 * @return {*}
 */
 export async function $delPreparePractise(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4}delete`, { tetea_pra_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 批量添加题目
 * @Author: Dyf
 * @return {*}
 */
export async function $batchadd(params) {
  try {
    return await HTTP.post(`${PREFIX4_2}batchadd`, params);
  } catch (error) {
    return error;
  }
}

/* 授课 - 课堂练习 */

/*******
 * @Descripttion: 课堂练习列表
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getTeachPractiseList(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}list_info`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课堂练习列表
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getTeachPractiseAllList(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}get_list`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课堂练习详情
 * @Author: Dyf
 * @param {number} teles_pra_id 课堂练习id
 * @return {*}
 */
export async function $teachPractiseDetail(teles_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}info`, { teles_pra_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课堂练习详情(授课习题切换)
 * @Author: Dyf
 * @param {number} teles_pra_id 课堂练习id
 * @return {*}
 */
export async function $teachPractiseDetail2(teles_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}practice_info`, { teles_pra_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 批量下发练习2024-03-21
 * @Author: Dyf
 * @param {tecla_id:授课课堂id,pra_ids:题目ids} params
 * @return {*}
 */
export async function $batch_assign_practice(params) {
  try {
    return await HTTP.post(`${PREFIX4_2}batch_assign_practice`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 查看单题答题信息
 * @Author: Dyf
 * @param {tecla_id:授课课堂id,teles_pra_id:题目ids} params
 * @return {*}
 */
export async function $getpracticestatistics(params) {
  try {
    return await HTTP.get(`${PREFIX4_2}getpracticestatistics`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 批量下发校内作业2024-03-26
 * @Author: Dyf
 * @param {tecla_id:授课课堂id,teles_hom_id:作业id,teque_ids:题目ids,timeout:倒计时时间 s 秒} params
 * @return {*}
 */
export async function $batch_assign_schoolHomework(params) {
  try {
    return await HTTP.post('teacher/stuhomework/batch_assign_schoolHomework', params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增课堂练习
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addTeachPractise(params) {
  try {
    return await HTTP.post(`${PREFIX4_2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑课堂练习
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editTeachPractise(params) {
  try {
    return await HTTP.post(`${PREFIX4_2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除课堂练习
 * @Author: Dyf
 * @param {number} tetea_pra_id 课堂练习id
 * @return {*}
 */
export async function $delTeachPractise(tetea_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}delete`, { tetea_pra_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下发课堂练习
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @param {number} teles_pra_id 课堂练习id
 * @return {*}
 */
export async function $distributePractise(tecla_id, teles_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}assign_practice`, {
      tecla_id,
      teles_pra_id,
    });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 获取手写板在线数据
 * @Author:LDX
 * @param {number} teles_pra_id 课堂练习id
 * @param {number} sccla_id 班级id
 * @return {*}
 */
export async function $getSubmitList(sccla_id, teles_pra_id) {
  try {
    return await HTTP.post(`${PREFIX4_2}get_submit_list`, {
      teles_pra_id,
      sccla_id
    });
  } catch (error) {
    return error;
  }
}
/*******
 * 获校内练习手写板在线数据
 */
export async function $get_school_submit_list(sccla_id, teles_pra_id) {
  try {
    return await HTTP.post("/teacher/stuhomework/get_school_submit_list", {
      teles_pra_id,
      sccla_id
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 批量添加题目
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
 export async function $batchaddPractise(params) {
  try {
    return await HTTP.post(`${PREFIX4}batchadd`, params);
  } catch (error) {
    return error;
  }
}

/* 作业 */

/*******
 * @Descripttion: 作业信息
 * @Author: Dyf
 * @param {number} tetea_id 备课关联id
 * @return {*}
 */
export async function $getPrepareHomework(tetea_id) {
  try {
    return await HTTP.post(`${PREFIX5}list_info`, { tetea_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 作业详情
 * @Author: Dyf
 * @param {number} tetea_hom_id 作业id
 * @return {*}
 */
export async function $prepareHomeworkDetail(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5}info`, { tetea_hom_id });
  } catch (error) {
    return error;
  }
}
export async function $prepareHomeworkInfo(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5}platschool_info`, { tetea_hom_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addPrepareHomework(params) {
  try {
    return await HTTP.post(`${PREFIX5}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editPrepareHomework(params) {
  try {
    return await HTTP.post(`${PREFIX5}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除作业
 * @Author: Dyf
 * @param {number} tetea_hom_id 作业id
 * @return {*}
 */
export async function $delPrepareHomework(tetea_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5}delete`, { tetea_hom_id });
  } catch (error) {
    return error;
  }
}

/* 授课 - 作业 */

/*******
 * @Descripttion: 作业信息
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getTeachHomework(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX5_2}list_info`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 作业列表
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getSchoolHomework(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5_2}get_list`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 作业详情
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $teachHomeworkDetail(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5_2}info`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 作业详情(校内作业切换)
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @param {number} teque_id 题目id
 * @return {*}
 */
export async function $teachHomeworkDetail2(teles_hom_id, teque_id) {
  try {
    return await HTTP.get(`${PREFIX5_2}schoolwork_info`, { teles_hom_id, teque_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addTeachHomework(params) {
  try {
    return await HTTP.post(`${PREFIX5_2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editTeachHomework(params) {
  try {
    return await HTTP.post(`${PREFIX5_2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除作业
 * @Author: Dyf
 * @param {number} teles_hom_id 作业id
 * @return {*}
 */
export async function $delTeachHomework(teles_hom_id) {
  try {
    return await HTTP.post(`${PREFIX5_2}delete`, { teles_hom_id });
  } catch (error) {
    return error;
  }
}

/* 授课 */

/*******
 * @Descripttion: 获取教师授课课程表
 * @Author: Dyf
 * @param {number} time 每周其中一天时间戳
 * @return {*}
 */
export async function $getTimetable(time) {
  try {
    return await HTTP.post(`${PREFIX6}get_teaclass`, { time });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取教师所授科目
 * @Author: Dyf
 * @param {number} sccla_id 班级id
 * @return {*}
 */
export async function $getTeachSubject(sccla_id) {
  try {
    return await HTTP.post("/teacher/teauseridentity/get_class_subject", { sccla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取授课id
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $getTeachId(params) {
  try {
    return await HTTP.post(`${PREFIX6}get_info`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 设置授课章节
 * @Author: Dyf
 * @param {number} sccou_cha_id 章\节id
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $setTeachChapter(sccou_cha_id, tecla_id) {
  try {
    return await HTTP.post(`${PREFIX6}set_chapter`, { sccou_cha_id, tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上课
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $attendClass(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX6}attend_class`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下课
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $overClass(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX6}finish_class`, { tecla_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 设置公开课
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @param {number} is_open 公开课状态 1：是   2：否
 * @return {*}
 */
export async function $setClassOpen(tecla_id, is_open = 2) {
  try {
    return await HTTP.post(`${PREFIX6}set_class_open`, { tecla_id, is_open });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传课堂回放
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @param {string} teles_cou_video 视频地址
 * @return {*}
 */
export async function $uploadClassReplay(tecla_id, teles_cou_video) {
  try {
    return await HTTP.post(`${PREFIX6}add_video`, {
      tecla_id,
      teles_cou_video,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程统计信息
 * @Author: LDX
 * @param {number} tecla_id 授课id
 * @return {*}
 */
export async function $getBboardList(tecla_id) {
  try {
    return await HTTP.post(`${PREFIX6}get_board_list`, {
      tecla_id
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 引用备课数据(授课)
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @param {number} tetea_id 备课id
 * @return {*}
 */
export async function $quotePrepare(tecla_id, tetea_id) {
  try {
    return await HTTP.post(`${PREFIX6}quote`, {
      tecla_id, tetea_id
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下发作业
 * @Author: Dyf
 * @param {number} tecla_id 授课id
 * @param {number} sthom_wor_deadlinetime 作业截至时间
 * @return {*}
 */
export async function $distributeHomework(tecla_id, sthom_wor_deadlinetime) {
  try {
    return await HTTP.post("/teacher/stuhomework/assign_homework", {
      tecla_id,
      sthom_wor_deadlinetime,
    });
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 下发校内作业
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $distributeSchoolHomework(params) {
  try {
    return await HTTP.post("/teacher/stuhomework/assignschoolwork", params);
  } catch (error) {
    return error;
  }
}
/* 课件资源 */

/*******
 * @Descripttion: 课件列表
 * @Author: Dyf
 * @param {string} type 列表类型  my：我的  collect：收藏  school：学校  platform：平台  public：公共
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $getCourseware(type, params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX7}${type}_list`,
      { ...params, pagesize },
      {
        load: true,
        loadEl: ".courseware-data-wrapper",
      }
    );
  } catch (error) {
    return error;
  }
}

// 收藏夹列表
export async function $teafavoritelinkList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.get(`${teafavoritelink}list`, { ...params, pagesize },
      {
        load: true,
        loadEl: ".courseware-data-wrapper",
      });
  } catch (error) {
    return error;
  }
}

// 新增收藏夹
export async function $teafavoritelinkadd(params) {
  try {
    return await HTTP.post(`${teafavoritelink}add`, params);
  } catch (error) {
    return error;
  }
}


//编辑收藏夹
export async function $teafavoritelinkedit(params) {
  try {
    return await HTTP.post(`${teafavoritelink}edit`, params);
  } catch (error) {
    return error;
  }
}

//删除收藏夹
export async function $teafavoritelinkdelete(tfl_id) {
  try {
    return await HTTP.post(`${teafavoritelink}delete`, { tfl_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增课件
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX7}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 收藏课件
 * @Author: Dyf
 * @param {number} params.tecou_id 课件id
 * @param {number} params.type 操作状态 1：收藏  2：取消
 * @param {number} params.tecou_col_origin 课件来源  10：我的  20：学校  30：平台
 * @return {*}
 */
export async function $collectCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX7}collect`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 共享课件
 * @Author: Dyf
 * @param {number} tecou_id 课件id
 * @return {*}
 */
export async function $shareCourseware(tecou_id) {
  try {
    return await HTTP.post(`${PREFIX7}shared`, { tecou_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课件详情
 * @Author: Dyf
 * @param {string} type 列表类型 school：学校  platform：平台
 * @param {number} tecou_id 课件id
 * @return {*}
 */
export async function $coursewareDetail(type, tecou_id) {
  let params = {};
  switch (type) {
    case "school":
      params.sccou_id = tecou_id;
      break;
    case "platform":
      params.plcou_id = tecou_id;
      break;
    default:
      params.tecou_id = tecou_id;
      break;
  }
  try {
    return await HTTP.post(
      `${PREFIX7}${(type && type + "_") || ""}info`,
      params
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 公共课件访问量增加
 * @Author: Dyf
 * @param {number} plcou_pub_id 课件id
 * @return {*}
 */
export async function $publicCoursewareAddView(plcou_pub_id) {
  try {
    return await HTTP.post(`${PREFIX7}public_add_num`, { plcou_pub_id });
  } catch (error) {
    return error;
  }
}


/*******
 * @Descripttion: 通过当前科目ID查询科目统计数据
 * @Author: Wyp
 * @param {array} sub_ids 科目ID组
 * @return {*}
 */
export async function $getSubjectCountData(params) {
  try {
    return await HTTP.post("/teacher/teauser/get_subject_count", params);
  } catch (error) {
    return error;
  }
}

